import io from 'socket.io-client';
import * as dev from '../api/dev.api';

let chatListSocket: SocketIOClient.Socket;
const initChatListConnection = () => {
  chatListSocket = io.connect(`${process.env.REACT_APP_SOCKET_DOMAIN}`, {
    path: '/chatlist',
    transports: ['websocket', 'polling'],
    secure: true,
    rejectUnauthorized: false,
    reconnection: true,
    agent: false,
    upgrade: false,
    forceNew: true
  });


  chatListSocket.on("connect", () => {
    dev.log({ roomId: 'chatlistSocket', event:'socket', value:'connected'});
  });

  chatListSocket.on("disconnect", (reason:any) => {
    dev.log({ roomId:'chatlistSocket', event:'socket', value:'disconnected', detail:reason});
    if(reason === 'io client disconnect') {
      setTimeout(() => {
      chatListSocket.connect();
    }, 0)
  }
  });

  chatListSocket.on('error', function(err: Error) {
    dev.log({ roomId: 'chatlistSocket', event:'socket', value:'error', detail:err});
    console.error(err);

    if (!chatListSocket) return;
    chatListSocket.close();
  });
};

export { chatListSocket, initChatListConnection };
