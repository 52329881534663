import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import packageJson from '../../package.json';

let fb: any;
let db: any;
const firebaseConfig = {
  apiKey: 'AIzaSyByo_YMnIDFX7i6IZVoKlpiCrvH8SB4vUQ',
  authDomain: `pipelettes-dev-default-rtdb.firebaseapp.com`,
  databaseURL: ` https://pipelettes-dev-default-rtdb.europe-west1.firebasedatabase.app`,
};

if(process.env.REACT_APP_TRACKER) {
  fb = firebase.initializeApp(firebaseConfig, 'Secondary');

  db = fb.database();
  db.ref(`/params/enable`).on('value', (e:any) => {
    const enable = e.val();
    if(!enable) firebase.app('Secondary').delete();
  });
}


export const log: any = ({roomId, event=null, value=null, detail=null} : any) => {
  if(!roomId) return;
  const date = Date.now();
  try{
    if(fb && db && !fb.isDeleted_) db.ref(`/roomList/${roomId}/log/`).push({type: 'pipelette', version: packageJson.version, date, event, value, detail:JSON.stringify(detail)});
  }catch(err){}
};