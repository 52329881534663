import React, { useContext, lazy, Suspense } from 'react';
import {  Route, Switch, Redirect } from 'react-router-dom';
import UserContext from './AppContext';
import Loader from './Loader';
import Desktop from './responsive/desktop';
import Mobile from './responsive/mobile';
import { IonReactRouter } from '@ionic/react-router';
import ConversationToast from './ConversationToast';
const Login = lazy(() => import('./login/Login'));
const Signup = lazy(() => import('./signup/Signup'));
const ChangePassword = lazy(() => import('./signup/ChangePassword'));
const Profile = lazy(() => import('./profile/Profile'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Chat = lazy(() => import('./chat/ChatMobile'));
const Mentions = lazy(() => import('./mentions/Mentions'));
const Help = lazy(() => import('./help/Help'));

interface IProps {
  myConv: string | null;
  newConversation: boolean;
  conversationToastClick: () => void;
}

const Routes: React.FC<IProps> = ({ myConv, newConversation, conversationToastClick }) => {
  const { token, role } = useContext(UserContext);

  return (
    <Suspense fallback={<Loader />}>
    <IonReactRouter>
      {/* <IonRouterOutlet> */}
        {!token && (
          <Switch>
            <Route component={Login} path='/login' />
            <Route component={Signup} path='/signup/:invitationToken' />
            <Route
              component={ChangePassword}
              path='/changer-mon-mot-de-passe/:id/:token'
            />
            <Redirect from='/' to='/login' />
          </Switch>
        )}
        {token && (
          <>
            <Desktop>
              <Switch>
                <Route
                  render={props => (
                    <Dashboard {...props} menuSelected='conversations' />
                  )}
                  path='/conversations/:id'
                />
                <Route component={Profile} path='/profile' />

                <Route
                  render={props => (
                    <Dashboard {...props} menuSelected='conversations' />
                  )}
                  path='/conversations'
                />

                <Route component={Help} path='/aide' />
                <Route component={Mentions} path='/mentions-legales' />

                <Route
                  render={props => (
                    <Dashboard {...props} menuSelected='calendar' />
                  )}
                  path='/calendar'
                />
                {role && role.indexOf('ROLE_ADMIN') !== -1 && (
                  <Route
                    render={props => (
                      <Dashboard {...props} menuSelected='reporting' />
                    )}
                    path='/reporting'
                  />
                )}
                {role && role.indexOf('ROLE_ADMIN') !== -1 && (
                  <Route
                    render={props => (
                      <Dashboard {...props} menuSelected='users' />
                    )}
                    path='/users'
                  />
                )}
                {myConv === null || JSON.parse(myConv).length === 0 ? (
                  <Redirect from='/' to='/conversations' />
                ) : (
                  <Redirect
                    from='/'
                    to={`/conversations/${JSON.parse(myConv)[0]}`}
                  />
                )}
              </Switch>
            </Desktop>

            <Mobile>
              <Switch>
                <Route component={Chat} path='/conversations/:id' />
                <Route component={Profile} path='/profile' />
                <Route
                  render={props => (
                    <Dashboard {...props} menuSelected='conversations' />
                  )}
                  path='/conversations'
                />
                <Route
                  render={props => (
                    <Dashboard {...props} menuSelected='calendar' />
                  )}
                  path='/calendar'
                />

                <Route component={Help} path='/aide' />
                <Route component={Mentions} path='/mentions-legales' />
                <Redirect from='/' to='/conversations' />
              </Switch>
            </Mobile>
          </>
        )}
        {/* </IonRouterOutlet> */}
        <ConversationToast visible={newConversation ? true : false} handleClick={conversationToastClick}/> 
      </IonReactRouter>
    </Suspense>
  );
};

export default Routes;
