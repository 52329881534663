/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import './Loader.css';

const LoaderStyle = (theme: any) => css`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.2);
  z-index: 5;
  position: absolute;

  .lds-rolling {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lds-rolling div,
  .lds-rolling div:after {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 6px solid ${theme.colors.blue};
    border-top-color: transparent;
    border-radius: 50%;
  }
  .lds-rolling div {
    top: 100px;
    left: 100px;
    transform: translate(-50%, -50%);
    animation: rolling 1s linear infinite;
  }
  .lds-rolling div:after {
  }
  .lds-rolling {
    width: 200px !important;
    height: 200px !important;
  }
`;

const Loader: React.FC = () => {
  return (
    <div className='lds-css ng-scope' css={theme => LoaderStyle(theme)}>
      <div style={{ width: '100%', height: '100%' }} className='lds-rolling'>
        <div />
      </div>
    </div>
  );
};

export default Loader;
