import React, { ReactNode } from 'react';
import MediaQuery from 'react-responsive';

interface IProps {
  children: React.ReactNode;
}

const Mobile: React.FC<IProps> = ({ children }) => {
  return <MediaQuery query='(max-width:734px)'>{children}</MediaQuery>;
};

export default Mobile;
