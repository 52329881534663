import React, { useState, useCallback } from 'react';
import App from './App';
import UserContext from './AppContext';
import { ThemeProvider } from 'emotion-theming';
import theme from './theme';
import MessageType from './chat/MessageType';
import UserType from './dashboard/calendar/UserType';

const AppContextProvider = () => {
  const [chatId, setChatId] = useState<string>('');
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );
  const [pseudo, setPseudo] = useState<string>('');
  const [chatAvailable, setChatAvailable] = useState<number[]>([]);
  const [otherKeys, setOtherKeys] = useState<{ [id: string]: string }>({});
  const [otherPseudos, setOtherPseudos] = useState<{ [id: string]: string }>(
    {}
  );
  const [users, setUsers] = useState<UserType[]>();
  const [startTimestamp, setStartTimestamp] = useState<Date>(new Date());
  const storedChatClosed = localStorage.getItem('chatClosed');
  const [chatClosed, setChatClosed] = useState<{ [id: string]: boolean }>(
    storedChatClosed === null ? {} : JSON.parse(storedChatClosed)
  );
  const [chatEvaluating, setChatEvaluating] = useState<{ [id: string]: boolean }>({});

  /* discussion signalé */
  const storedChatReported = localStorage.getItem('chatReported');
  const [chatReported, setChatReported] = useState<{ [id: string]: boolean }>(
    storedChatReported === null ? {} : JSON.parse(storedChatReported)
  );

  const [chatMessages, setChatMessages] = useState<{
    [id: string]: MessageType[];
  }>({});

  const storedRole = localStorage.getItem('role');
  const [role, setRole] = useState<string[]>(
    storedRole === null ? [] : JSON.parse(storedRole)
  );

  /**
   * suppression de la room
   * dans le localstorage et
   * dans le state chatMessages
   * 
   * @param roomId: number
   */
  function deleteRoom(roomId:number, setOtherPseudos:any) {
    localStorage.removeItem(`pseudo__${roomId}`);
    localStorage.removeItem(`messages__${roomId}`);
    localStorage.removeItem(`publicKey__${roomId}`);
    localStorage.removeItem(`startTimestamp__${roomId}`);

    const convs = localStorage.getItem('myConversations');
    if (
      convs !== null &&
      JSON.parse(convs).indexOf(roomId) !== -1
    ) {
      const newVal = JSON.parse(convs).filter(
        (conv: number) => conv !== roomId
      );
      if (newVal.length === 0) {
        localStorage.removeItem('myConversations');
      } else {
        localStorage.setItem('myConversations', JSON.stringify(newVal));
      }
    }

    setChatMessages(m => {
      delete m[roomId];
      return m;
    })

    /* ⚠️ funcking hack to rerender App component  */
    setOtherPseudos((s:any) => ({...s}));
  }

  /**
   * put all seen param to true
   * 
   * @param roomId 
   */
  const setSeenByRoomId = useCallback((roomId:number) => {
    const messagesStored = localStorage.getItem(`messages__${roomId}`);

    if (messagesStored !== null) {
      const newMessageStored: MessageType[] = JSON.parse(messagesStored)
        .filter((m:MessageType) => !m.isTyping)
        .map((messageStored: MessageType) => ({ 
          ...messageStored, 
          seen: true 
        }));

      localStorage.setItem(
        `messages__${roomId}`,
        JSON.stringify(newMessageStored)
      );

      setChatMessages && setChatMessages(prev => (
        { ...prev, [roomId]: (newMessageStored) }
      ));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider
        value={{
          pseudo,
          setPseudo,
          chatId,
          setChatId,
          token,
          setToken,
          chatAvailable,
          setChatAvailable,
          otherKeys,
          setOtherKeys,
          otherPseudos,
          setOtherPseudos,
          startTimestamp,
          setStartTimestamp,
          chatMessages,
          setChatMessages,
          chatClosed,
          setChatClosed,
          chatEvaluating,
          setChatEvaluating,
          chatReported,
          setChatReported,
          role,
          setRole,
          users,
          setUsers,
          deleteRoom: (e) => deleteRoom(e, setOtherPseudos),
          setSeenByRoomId,
        }}
      >
        <App />
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default AppContextProvider;
