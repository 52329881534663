import openSocket from 'socket.io-client';
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import * as dev from '../api/dev.api';
import { encrypt } from '../crypto-worker';
let chatSockets: { [roomId: number]: SocketIOClient.Socket };
type MessagesQueue =  any[] | undefined;

let messageQueue: { [id: number]: MessagesQueue[] } = {};

const initConnection = (roomId: number, token: string) => {
  
  dev.log({ roomId, event:'socket',  value:'connecting...'});

  const tempMessage = localStorage.getItem(`tempMessages__${roomId}`);
  const tempMessageStored = tempMessage === null ? [] : JSON.parse(tempMessage);
  messageQueue = { ...messageQueue, [roomId]: tempMessageStored };

  const chatSocket = openSocket.connect(
    `${process.env.REACT_APP_SOCKET_DOMAIN}`,
    {
      query: `roomId=${roomId}&token=${token}`,
      path: '/sf',
      transports: ['websocket', 'polling'],
      secure: true,
      rejectUnauthorized: false,
      reconnection: true,
      // agent: false,
      // upgrade: false,
      // forceNew: true
    }
  );

  chatSocket.on("connect", () => {
    dev.log({ roomId, event:'socket', value:'connected'});

    chatSocket.emit('WAKEUP')

    if (messageQueue[roomId].length === 0) return;

    dev.log({ roomId, event:'socket', value:'envoie des messages non envoyés suite à la reconnection'});
    messageQueue[roomId].forEach(message => {
      if (!chatSocket) return;
      chatSocket.emit('MESSAGE', {
        message
      });
    });
    messageQueue[roomId] = [];
    localStorage.removeItem(`tempMessages__${roomId}`);
  });


  chatSocket.on("disconnect", (reason:any) => {
    dev.log({ roomId, event:'socket', value:'disconnected', detail:reason});
    if(reason === 'io client disconnect') {
      setTimeout(() => {
        chatSocket.connect();
      }, 0)
    }
  });

  chatSocket.on('error', function(err: Error) {
    dev.log({ roomId, event:'socket error', value: err.message});
    if (err.name === 'close') {
      //on enlève l'id du chat du storage
      const chatAwait = localStorage.getItem('chat__await');
      if (chatAwait !== null) {
        const newChatAwait = JSON.parse(chatAwait).filter(
          (elt: string) => parseInt(elt) !== roomId
        );
        localStorage.setItem('chat__await', JSON.stringify(newChatAwait));
      }

    }
    if (err.name === 'invalid_token') {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    console.error(err);
  });

  chatSockets = { ...chatSockets, [roomId]: chatSocket };
};

function chunkString(str: string, size: number) {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

const sendMessage = (roomId: number, message: string, messageId?:string, callback?:any) => {
  const messageArray = chunkString(message.trim(), 50);
  const messageArrayEncrypted = messageArray?.map(currentMessage => encrypt(currentMessage, roomId))
  const chatSocket = chatSockets[roomId];

  if (!chatSocket) {
    const token = localStorage.getItem('token');
    if (token === null) return;
    initConnection(roomId, token);
  }

  if (!chatSocket) return;

  if (chatSocket.disconnected) {
    messageQueue[roomId].push(messageArrayEncrypted);
    localStorage.setItem(
      `tempMessages__${roomId}`,
      JSON.stringify(messageQueue[roomId])
    );
  }
  else {
    dev.log({ roomId, event:'socket message', value:'envoi d\'un message'});
    let notSent = false;
    const sto = setTimeout(() => {
      notSent = true;
      callback && callback(false);
    }, 10000);
    chatSocket.emit('MESSAGE', {
      message: messageArrayEncrypted,
      messageId: messageId || uuidv4(),
      timestamp: Date.now(),
    }, () => {
      clearTimeout(sto);
      notSent && callback && callback(true);
    });
  }
};


// const sendInfo = (roomId: number, publicKey: string, pseudo: string) => {
//   const chatSocket = chatSockets[roomId];
//   if (!chatSocket) {
//     const token = localStorage.getItem('token');
//     if (token === null) return;
//     initConnection(roomId, token);
//   }
//   if (!chatSocket) return;
//   chatSocket.emit('INFO', { publicKey, pseudo });
// };

const shareInfo = (roomId: number, publicKey: string, pseudo: string) => {
  const chatSocket = chatSockets[roomId];
  const token = localStorage.getItem('token');
  if (token === null) return;
  
  if (!chatSocket) {
    initConnection(roomId, token);
    return
  }

  const { id } = jwtDecode(token);
  chatSocket.emit('SHARE_INFO_SF', { publicKey, pseudo, userId: id });
};

const responseInfo = (roomId: number, publicKey: string, pseudo: string) => {
  const chatSocket = chatSockets[roomId];
  if (!chatSocket) {
    const token = localStorage.getItem('token');
    if (token === null) return;
    initConnection(roomId, token);
  }
  if (!chatSocket) return;
  chatSocket.emit('RESPONSE_INFO_SF', { publicKey, pseudo });

  const oldAwaitChat = localStorage.getItem('chat__await');
  if (oldAwaitChat !== null) {
    localStorage.setItem(
      'chat__await',
      JSON.stringify(
        JSON.parse(oldAwaitChat).filter((chat: number) => chat !== roomId)
      )
    );
  }
};

const closeChat = (roomId: number) => {
  dev.log({ roomId, event: 'click', value:'fermeture du chat'});
  const chatSocket = chatSockets[roomId];
  if (!chatSocket) {
    const token = localStorage.getItem('token');
    if (token === null) return;
    initConnection(roomId, token);
  }
  if (!chatSocket) return;
  chatSocket.emit('CLOSE');
  chatSocket.close();
};

const reportChat = (roomId: number, reason: string) => {
  const chatSocket = chatSockets[roomId];
  if (!chatSocket) {
    const token = localStorage.getItem('token');
    if (token === null) return;
    initConnection(roomId, token);
  }
  if (!chatSocket) return;
  
  chatSocket.emit('REPORT', { reason });
  chatSocket.close();
};

const setIsTyping = (roomId: number, isTyping: boolean) => {
  const chatSocket = chatSockets[roomId];
  if (!chatSocket) {
    const token = localStorage.getItem('token');
    if (token === null) return;
    initConnection(roomId, token);
  }
  if (!chatSocket) return;
  chatSocket.emit('SF_IS_TYPING', { isTyping });
};

export {
  chatSockets,
  initConnection,
  sendMessage,
  shareInfo,
  responseInfo,
  closeChat,
  setIsTyping,
  reportChat,
};
