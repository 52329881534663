/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {useState, useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import Routes from './Routes';
import { AdminApi } from './api/admin.api';
import * as dev from './api/dev.api';
import { initCrypt, decrypt } from './crypto-worker';
import theme from './theme';
import './fonts.css';
import jwtDecode from 'jwt-decode';
import { chatListSocket, initChatListConnection } from './chat/ChatListSocket';
import ConversationPreviewType from './ConversationPreviewType';
import {
  chatSockets,
  initConnection,
  shareInfo,
  responseInfo
} from './chat/ChatSocket';
import UserContext from './AppContext';
import { sendMessage } from './chat/ChatSocket';
import { IonApp } from '@ionic/react';
import PushPWA, { initPushNotification } from './PushPWA';
import {
  Plugins,
  PushNotificationToken,
  AppState,
} from '@capacitor/core';
import '@ionic/react/css/core.css';

const { PushNotifications, App: _App } = Plugins;

const GenericStyle = css`
  margin: 0;
  color: ${theme.colors.blue};
  font-family: 'Work Sans';
  overflow-x: hidden;
  @keyframes autofill {
    to {
      color: ${theme.colors.blue};
      background: transparent;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  select {
    background: #FFF;
  }
`;

type MyError = Error & {
  messages?: string[];
}
const App: React.FC = () => {
  const {
    chatMessages,
    setOtherKeys,
    setOtherPseudos,
    setStartTimestamp,
    setToken,
    token,
    setPseudo,
    setChatAvailable,
    pseudo,
    setChatClosed,
    setChatMessages,
    otherPseudos
  } = useContext(UserContext);
  const myConv = localStorage.getItem('myConversations');

  const [newConversation, setNewConversation] = useState(false);

  const addMessage = useCallback((messageDecrypted: string, roomId: number, messageId?: string) => {
    const otherPseudo = localStorage.getItem(`pseudo__${roomId}`);
    const messagesLocal = localStorage.getItem(`messages__${roomId}`);
    const messages =
      messagesLocal === null ? [] : JSON.parse(messagesLocal);

    if(messageId && messages.some((m:any) => m.messageId && m.messageId === messageId)) {
      return;
    }

    localStorage.setItem(
      `messages__${roomId}`,
      JSON.stringify([
        ...messages,
        {
          message: messageDecrypted,
          me: false,
          seen: false,
          otherPseudo: otherPseudo === null ? '' : otherPseudo,
          timestamp: new Date().getTime(),
          roomId,
          messageId,
        }
      ])
    );

    if (setChatMessages){
      setChatMessages(prev => {
        const oldValue =
          prev[roomId] !== undefined
            ? prev[roomId].filter(elt => !elt.isTyping)
            : [];
        return {
          ...prev,
          [roomId]: [
            ...oldValue,
            {
              message: messageDecrypted,
              me: false,
              seen: false,
              otherPseudo: otherPseudo === null ? '' : otherPseudo,
              timestamp: new Date().getTime(),
              messageId,
            }
          ]
        };
      });
    }

  }, [setChatMessages]);


  useLayoutEffect(() => {

    
    if(token){
      
      // desktop notifications
      initPushNotification().then((pushToken: any) => {
        if(chatListSocket) {
          AdminApi.setPushToken(pushToken);
        }
      });

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermission().then( result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (pushToken: PushNotificationToken) => {
          if(chatListSocket) {
            AdminApi.setPushToken(pushToken.value);
          }
        }
      );

    }


    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 100);
    });

    return () => {
      PushNotifications.removeAllListeners();
    }
  }, [token]);

    
  const initMessageConnection = useCallback(() => {
    const myConv = localStorage.getItem('myConversations') || '[]';
    JSON.parse(myConv).forEach((roomId: number) => {

      if (!chatSockets || !chatSockets[roomId]) {
        initConnection(roomId, token || '');
      }

      const chatSocket = chatSockets && chatSockets[roomId];
      
      if (!chatSocket) return;
    
      chatSocket.on('CLOSE', () => {
        setChatClosed && setChatClosed(prev => {
          return {
            ...prev,
            [roomId]: true
          };
        });
        const storedVal = localStorage.getItem('chatClosed');
        const storedValObj = storedVal === null ? {} : JSON.parse(storedVal);
  
        localStorage.setItem(
          'chatClosed',
          JSON.stringify({ ...storedValObj, [roomId]: true })
        );
        chatSocket.close();
      });
  
      chatSocket.on('error', function(err: MyError) {
        if (err.name === 'close') {

          const messages = err.messages;
          if(messages) {
            const mess = messages.map(currentMessageSplit => JSON.parse(currentMessageSplit))
  
            mess.forEach(messageToDecode => {
              const messageDecrypted = messageToDecode.map((currentMessage: string) => decrypt(currentMessage)).join('');
              if (messageDecrypted === null || messageDecrypted === "") return;
              addMessage(messageDecrypted, roomId)
            })
          }

          setChatClosed && setChatClosed(prev => {
            return {
              ...prev,
              [roomId]: true
            };
          });
          const storedVal = localStorage.getItem('chatClosed');
          const storedValObj =
            storedVal === null ? {} : JSON.parse(storedVal);
  
          localStorage.setItem(
            'chatClosed',
            JSON.stringify({ ...storedValObj, [roomId]: true })
          );
        }
        if (!chatSocket) return;
        chatSocket.close();
      });
  
      chatSocket.off('USER_IS_TYPING');
      chatSocket.on(
        'USER_IS_TYPING',
        ({ isTyping }: { isTyping: boolean }) => {
          if (!setChatMessages) return;
          if (isTyping) {
            setChatMessages(prev => {
              const oldValue =
                prev[roomId] !== undefined
                  ? prev[roomId].filter(elt => !elt.isTyping)
                  : [];
              return {
                ...prev,
                [roomId]: [
                  ...oldValue,
                  {
                    message: '',
                    me: false,
                    seen: true,
                    isTyping: true
                  }
                ]
              };
            });
          } else {
            setChatMessages(prev => {
              const oldValue = prev[roomId] !== undefined ? prev[roomId] : [];
              return {
                ...prev,
                [roomId]: [...oldValue.filter(elt => !elt.isTyping)]
              };
            });
          }
        }
      );
  
      chatSocket.off('WAKEUP_RETURN');
      chatSocket.on('WAKEUP_RETURN', function(message: any[]) {
        dev.log({ roomId, event:'socket message', value:`SF Wakeup Return avec ${message.length} messages`});

        message.forEach(currentMessageSplit => {
          chatSocket.emit('MESSAGE_RECEIVED', currentMessageSplit.messageId);
          const messageDecrypted = currentMessageSplit.message.map((currentMessage: string) => decrypt(currentMessage)).join('');
          if (messageDecrypted === null || messageDecrypted === "") return;
          addMessage(messageDecrypted, roomId, currentMessageSplit.messageId);
        });

        chatSocket.emit('CHECK_MESSAGE_RECEIVED', (messageListPending:any) => {
          setChatMessages && setChatMessages(m => {

            const messagesList = m[roomId].map(e => {
              return {...e, pending: messageListPending.includes(e.messageId)};
            });

            localStorage.setItem(
              `messages__${roomId}`,
              JSON.stringify( messagesList)
            );

            return {...m, [roomId]: messagesList};
          });
          
        });
      })
  
      chatSocket.off('MESSAGE');
      chatSocket.on('MESSAGE', ({ message, messageId }: { message: string[],  messageId: string}) => {

        dev.log({ roomId, event:'socket message', value:'message recu'});
        const messageDecrypted = message.filter(currentMessage => decrypt(currentMessage) !== null).map(currentMessage => decrypt(currentMessage)).join('');

        chatSocket.emit('MESSAGE_RECEIVED', messageId);

        if (messageDecrypted === null || messageDecrypted === "") {
          setChatMessages && setChatMessages(prev => {
            const oldValue = prev[roomId] !== undefined ? prev[roomId] : [];
            return {
              ...prev,
              [roomId]: [...oldValue.filter(elt => !elt.isTyping)]
            };
          });
          return;
        }
        addMessage(messageDecrypted, roomId, messageId);
      });
      
      chatSocket.off('MESSAGE_RECEIVED');
      chatSocket.on('MESSAGE_RECEIVED', ( messageId:string ) => {

        if (!setChatMessages) return;

        setChatMessages(messages => {

          const newMessages = messages[roomId].map((message) => message.messageId === messageId ? {...message, pending: false} : message);

          localStorage.setItem(
            `messages__${roomId}`,
            JSON.stringify(newMessages)
          );

          return {...messages, [roomId]: newMessages}
        });

        
      }); 


    });

  }, [addMessage, setChatClosed, setChatMessages, token]);

  const initConversation = (roomId: number) => {
    const storeTimestamp = localStorage.getItem(`startTimestamp__${roomId}`);
    const oldMessages = localStorage.getItem(`messages__${roomId}`);

    let pseudoOther: string = '';
    const storedPseudo = localStorage.getItem(`pseudo__${roomId}`);
    if (storedPseudo !== null) pseudoOther = storedPseudo;
    else if (otherPseudos !== undefined && otherPseudos[roomId] !== undefined)
      pseudoOther = otherPseudos[roomId];

    if (pseudo !== undefined && pseudoOther !== '') {
      const defaultMessages: ConversationPreviewType[] = [
        {
          message: `Salut moi c’est ${pseudo}, que t’arrive t-il ?`,
          timestamp:
            storeTimestamp !== null
              ? parseInt(storeTimestamp)
              : new Date().getTime(),
          me: true,
          seen: true,
          otherPseudo: pseudoOther,
          roomId
        }
      ];
      if (oldMessages !== null) {
        if (setChatMessages) {
          setChatMessages(prev => {
            return {
              ...prev,
              [roomId]: JSON.parse(oldMessages)
            };
          });
        }
      } else {
        if (setChatMessages) {
          setChatMessages(prev => {
            return {
              ...prev,
              [roomId]: [
                {
                  message: `Salut moi c’est ${pseudo}, que t’arrive t-il ?`,
                  timestamp:
                    storeTimestamp !== null
                      ? parseInt(storeTimestamp)
                      : new Date().getTime(),
                  me: true,
                  seen: true,
                  otherPseudo: pseudoOther
                }
              ]
            };
          });
        }

        localStorage.setItem(
          `messages__${roomId}`,
          JSON.stringify(defaultMessages)
        );
        setTimeout(() => {
          sendMessage(roomId, defaultMessages[0].message);
        }, 500);
      }
    }
  };

  const setInformations = (
    roomId: number,
    publicKeyOther: string,
    pseudoOther: string
  ) => {
    localStorage.setItem(`publicKey__${roomId}`, publicKeyOther);
    localStorage.setItem(`pseudo__${roomId}`, pseudoOther);
    localStorage.setItem(
      `startTimestamp__${roomId}`,
      JSON.stringify(new Date().getTime())
    );

    const myConv = localStorage.getItem('myConversations');
    if (myConv === null)
      localStorage.setItem('myConversations', JSON.stringify([roomId]));
    else {
      localStorage.setItem(
        'myConversations',
        JSON.stringify([
          ...JSON.parse(myConv).filter((id: string) => parseInt(id) !== roomId),
          roomId
        ])
      );
    }

    if (!setOtherKeys || !setOtherPseudos || !setStartTimestamp) return;
    const timestampStored = localStorage.getItem(`startTimestamp__${roomId}`);
    if (timestampStored === null) {
      setStartTimestamp(new Date());
    } else {
      setStartTimestamp(new Date(timestampStored));
    }

    setOtherKeys((prev: { [id: string]: string }) => {
      return { ...prev, roomId: publicKeyOther };
    });
    setOtherPseudos((prev: { [id: string]: string }) => {
      return { ...prev, roomId: pseudoOther };
    });
  };

  useEffect(() => {
    
    if (
      (token === null ||
      !setToken ||
      !setPseudo ||
      !setChatAvailable ||
      !setChatClosed)
    )
      return;

    const { username, exp } = jwtDecode(token);
    if (exp * 1000 < new Date().getTime()) {
      localStorage.removeItem('token');
      setToken(null);
    } else {

      if (!localStorage.getItem('publicKey')) initCrypt();
      setPseudo(username);
      initChatListConnection();

      chatListSocket.off('ROOM_LIST');
      chatListSocket.on('ROOM_LIST', function(data: number[]) {
        setChatAvailable(
          data.filter(id => {
            if (myConv === null) return id;

            return JSON.parse(myConv).indexOf(id) === -1;
          })
        );
      });

      chatListSocket.off('NEW_CHAT');
      chatListSocket.on('NEW_CHAT', function([id]: [number]) {
        setNewConversation(true)
        setTimeout(() => {
          setNewConversation(false);
        }, 3000);
        setChatAvailable(prev => [...prev.filter(chat => chat !== id), id]);
      });

      chatListSocket.off('CHAT_REMOVED');
      chatListSocket.on('CHAT_REMOVED', function(roomId: string) {
        setChatAvailable(prev => {
          return prev.filter(chat => {
            return chat !== parseInt(roomId);
          });
        });
      });

      const myConv: string = localStorage.getItem('myConversations') || '[]';
      const awaitChat: string = localStorage.getItem('chat__await') || '[]';
      const publicKey = localStorage.getItem('publicKey');

      if (awaitChat !== null && publicKey) {
        JSON.parse(awaitChat).forEach((roomId: number) => {

          if (!chatSockets || !chatSockets[roomId]) {
            initConnection(roomId, token);
          }
          
          const chatSocket = chatSockets[roomId];
          if (!chatSocket) return;

          if (pseudo) shareInfo(roomId, publicKey, pseudo);

          if (!chatSocket) return;

          chatSocket.off('RESPONSE_INFO_USER');
          chatSocket.on(
            'RESPONSE_INFO_USER',
            ({
              publicKeyOther,
              pseudoOther
            }: {
              publicKeyOther: string;
              pseudoOther: string;
            }) => {
              const oldAwaitChat = localStorage.getItem('chat__await');
              if (oldAwaitChat !== null) {
                localStorage.setItem(
                  'chat__await',
                  JSON.stringify(
                    JSON.parse(oldAwaitChat).filter(
                      (chat: number) => chat !== roomId
                    )
                  )
                );
              }
              setInformations(roomId, publicKeyOther, pseudoOther);
              initConversation(roomId);
            }
          );

          chatSocket.off('SHARE_INFO_USER');
          chatSocket.on(
            'SHARE_INFO_USER',
            ({
              publicKeyOther,
              pseudoOther
            }: {
              publicKeyOther: string;
              pseudoOther: string;
            }) => {
              setInformations(roomId, publicKeyOther, pseudoOther);
              if (pseudo) responseInfo(roomId, publicKey, pseudo);
              initConversation(roomId);
            }
          );
        });
      }

      if (myConv === null) return;

      initMessageConnection();

    }

    return () => {
      if (!chatListSocket) return;
      chatListSocket.off('ROOM_LIST');
      chatListSocket.off('NEW_CHAT');
      chatListSocket.off('CHAT_REMOVED');
    };
    // eslint-disable-next-line
  }, [token, myConv]);

  useEffect(() => {
    _App.addListener('appStateChange', (state: AppState) => {
      console.log('App state changed. Is active?', state.isActive);
      if(state.isActive) {
        initMessageConnection();
      }
    });
  }, [chatMessages, initMessageConnection]);

  useEffect(() => {

    if(!myConv) return;
    JSON.parse(myConv).forEach((roomId: number) => {
      const messagesStored = localStorage.getItem(`messages__${roomId}`);
      if (messagesStored !== null) {
        const messages = JSON.parse(messagesStored);
        setChatMessages && setChatMessages(prev => {
          return { ...prev, [roomId]: messages };
        });
      }
    });
    
  }, [myConv, setChatMessages]);

  return (
    <IonApp>
      <PushPWA />
      <div css={GenericStyle} className='App'>
        <Routes myConv={myConv} newConversation={newConversation} conversationToastClick={() => setNewConversation(false)}/>
      </div>
    </IonApp>
  );
};

export default App;
