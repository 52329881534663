import React, { createContext } from 'react';
import MessageType from './chat/MessageType';
import UserType from './dashboard/calendar/UserType';

type UserContextType = {
  pseudo?: string;
  setPseudo?: React.Dispatch<React.SetStateAction<string>>;
  chatId?: string;
  setChatId?: React.Dispatch<React.SetStateAction<string>>;
  token: string | null;
  setToken?: React.Dispatch<React.SetStateAction<string | null>>;
  chatAvailable: number[];
  setChatAvailable?: React.Dispatch<React.SetStateAction<number[]>>;
  otherKeys?: { [id: string]: string };
  setOtherKeys?: React.Dispatch<
    React.SetStateAction<{
      [id: string]: string;
    }>
  >;
  otherPseudos?: { [id: string]: string };
  setOtherPseudos?: React.Dispatch<
    React.SetStateAction<{
      [id: string]: string;
    }>
  >;
  setUnreadMessage?: React.Dispatch<
    React.SetStateAction<{ [id: string]: number }>
  >;
  startTimestamp: Date;
  setStartTimestamp?: React.Dispatch<React.SetStateAction<Date>>;
  chatClosed?: { [id: string]: boolean };
  setChatClosed?: React.Dispatch<
    React.SetStateAction<{ [id: string]: boolean }>
  >;
  chatEvaluating?: { [id: string]: boolean };
  setChatEvaluating?: React.Dispatch<
    React.SetStateAction<{ [id: string]: boolean }>
  >;
  chatReported?: { [id: string]: boolean };
  setChatReported?: React.Dispatch<
    React.SetStateAction<{ [id: string]: boolean }>
  >;
  chatMessages?: { [id: string]: MessageType[] };
  setChatMessages?: React.Dispatch<
    React.SetStateAction<{ [id: string]: MessageType[] }>
  >;
  role?: string[];
  setRole?: React.Dispatch<React.SetStateAction<string[]>>;
  users?: UserType[];
  setUsers?: React.Dispatch<React.SetStateAction<UserType[] | undefined>>;
  deleteRoom: (roomId: number) => void;
  setSeenByRoomId: (roomId: number) => void;
};

const UserContext = createContext<UserContextType>({
  token: null,
  chatAvailable: [],
  startTimestamp: new Date(),
  deleteRoom: function() {},
  setSeenByRoomId: function() {},
});
export default UserContext;
