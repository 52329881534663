/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { withRouter } from 'react-router';

const ConversationToastStyled = (theme: any) => css`
  background: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.blue};
  padding: 20px;
  width: 250px;
  top: -20px;
  z-index: -5;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  opacity: 0;
  transition: all 0.5s linear;

  &.animated {
    top: 50px;
    opacity: 1;
    z-index: 5;
  }

  p  {
    margin: 0;
    color: ${theme.colors.blue};
    text-align: center;
    position: relative;
    transform: translateY(0px);

    &.error-title {
      font-size: 16px;
      font-weight: 600;
    }
    &.error-desc {
      font-size: 14px;
    }
  }
`;

const ConversationToast: React.FC<any> = ({ visible, title, history, handleClick }) => {
  return (
    <div
      css={theme => ConversationToastStyled(theme)}
      className={visible ? 'animated' : ''}
      onClick={() => {
          history.push('/')
          handleClick();
      }}
    >
      <p className='error-title'>
        {title ? title : 'Nouvelle conversation !'}
      </p>
    </div>
  );
};

export default withRouter(ConversationToast);
