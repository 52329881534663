/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { useEffect, useState } from 'react';

const PwaStyle = (theme: any) => css`
  z-index: 99999999;
  position: absolute;
  width: 100%;
  padding: 0 10px 10px;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  .banner {
    display: block;
    align-items: center;
    margin: auto;
    max-width: 640px;
    padding: 30px;
    background-color: #FFF;
    border-radius: 0 0 5px 5px;
    box-shadow: 1px 1px 10px rgba(0,0,0,.1);
    color: ${theme.colors.blue};

    @media (min-width: 640px) {
      display: flex;
    }
  }

  p {
    font-size: 17px;
    margin: 0;
    margin-bottom: 20px;

    @media (min-width: 640px) {
      margin-bottom: 0px;
    }
  }

  .button {
    display: block;
    border-radius: 30px;
    border: none;
    padding: 10px 20px;
    background-color: ${theme.colors.blue};
    color: #FFF;
    font-size: 16px;
    margin: auto;
    margin-right: 0;
    font-family: 'Anton'};
  }
`;

const Pwa = () => {
  const [isPwaUpdated, setPwaUpdated] = useState<boolean>(false);

  useEffect(() => {

    document.addEventListener(
      'serviceWorkerOnUpdate',
      () => setPwaUpdated(true),
      false
    );

  }, []);

  function unregister () {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister().then(() => {
        window.location.reload();
      });
    });
  }

  if(!isPwaUpdated) return null;

  return (
    <div css={theme => PwaStyle(theme)} >
      <div className="banner" >
        <div>
          <p>Une nouvelle version du site est disponible.</p>
        </div>
        <button 
          className="button"
          type="button"
          onClick={() => unregister()}
        >
          Mettre à jour
        </button>
      </div>
    </div>
  )
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
});

function initPushNotification() {

  return new Promise((resolve, reject) => {

    try { 
      const messaging = firebase.messaging();
    
      messaging.getToken({vapidKey: process.env.REACT_APP_FB_VAPIDKEY})
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
        } else {
          console.log('Push Notification :', 'No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('Push Notification :', 'An error occurred while retrieving token.');
      });

    } catch (err) {
      console.error('Push Notification :', 'Firebase Message nécessite une connexion sécurisé');
    }
    
  })

};

export default Pwa;
export {initPushNotification}