import axiosInstance from './utils/axiosInstance';

export class AdminApi {
  static async setPushToken(token) {
    try {
      const { data } = await axiosInstance.post('/v1/user/setpushtoken', { token });
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getAllUsers() {
    try {
      const { data } = await axiosInstance.get('/v1/users');
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getSF() {
    try {
      const { data } = await axiosInstance.get('/v1/users?roles=1');
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async activateDesactivateUser(userId, activate) {
    try {
      if (activate) {
        await axiosInstance.get(`/v1/user/${userId}/activate`);
      } else {
        await axiosInstance.get(`/v1/user/${userId}/deactivate`);
      }
    } catch (error) {
      throw error;
    }
  }

  static async putUser(
    userId,
    lastName,
    firstName,
    nickname,
    mobile,
    email,
    role
  ) {
    try {
      await axiosInstance.put(`/v1/user/${userId}`, {
        lastName,
        firstName,
        nickname,
        mobile,
        email,
        role
      });
    } catch (error) {
      throw error;
    }
  }

  static async GetReporting(page, dates) {
    try {
      const { data } = await axiosInstance.get(`/v1/survey?page=${page}${dates ? `&from=${dates.from}&to=${dates.to}` : ''}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async GetReportingSorted(page, dates, order, direction) {
    try {
      const { data } = await axiosInstance.get(`/v1/survey?page=${page}&order=${order}&direction=${direction}${dates ? `&from=${dates.from}&to=${dates.to}` : ''}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getActiveConversations() {
    try {
      const { data } = await axiosInstance.get('/v1/chat/activity');
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async InviteSF(email) {
    try {
      await axiosInstance.post('/v1/invitation', {
        email
      });
    } catch (error) {
      throw error;
    }
  }
}
