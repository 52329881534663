import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  // timeout: 5000,
  headers: {
    contentType: 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.error(error);
    // localStorage.removeItem('token');
    return Promise.reject(error);
  }
);

export default axiosInstance;
